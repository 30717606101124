import React, {useRef, useEffect} from 'react';
import { Item } from 'cms/items/item';
import cmsUtils from 'cms/utils/cmsUtils';
import  utils from 'utils';
import Slider from "react-slick";

export function BannerPanel(props) {
  const item = props.item;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};

  //console.log('item', item, subItems);

  const heightSet = cmsUtils.payload(item, 'HeightSet') || 'standard';

  const slides = subItems.map((subItem, index) =>
    <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
  );
  const bannerPanel = useRef(null);
  var settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    autoplaySpeed: 3000,
    fade: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange : ($currentPosition) => {
      //console.log("abc", bannerPanel.current);
      var x = bannerPanel.current.querySelectorAll("div.slick-slide"); 
      //console.log("x", x);
      for(var i = 0; i < x.length; i++){
        x[i].style.zIndex = 0;
        if(i === $currentPosition){
          x[i].style.zIndex = 1;
        }
      }
    }
  };
  const cssClass = utils.classNames('cms_item', 'bannerPanel', `bannerWrapperSize--${heightSet}`, item.cssClass || item.anchorName || '');
  useEffect(()=>{
    var x = bannerPanel.current.querySelectorAll("div.slick-slide");
    for(var i = 0; i < x.length; i++){
      x[i].style.zIndex = 0;
    }
    x[0].style.zIndex = 1;
  }, []);

  return (
    <div ref={bannerPanel} className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      <Slider {...settings} >
        {slides}
      </Slider>
    </div>
  );
}