import React, { useState, useEffect } from 'react';
import { useRouter } from 'components';
import { useGet } from 'components';
import { Item } from 'cms/items/item';
import { NotFound } from 'components';
import env from 'env';
import { useStore } from 'store';
import { ErrorPopup, Loading } from 'components';
import { useInstagram } from 'components';
import { MetaTag } from 'components';
import cmsUtils from 'cms/utils/cmsUtils';
import utils from 'utils';
import { gtm } from 'lib/tracking';

const log = (...args) => {
  //console.log(args);
}
const areEqual = (prevProps, nextProps) => {
  return (prevProps.pageData === nextProps.pageData)
};
const PageMemo = React.memo(Page, areEqual)

export function ViewPage(props) {
  const { dispatch } = useStore();
  const [pageLoaded, setPageLoaded] = useState(false);
  //const [page, setPage] = useState(null);
  //const path = usePath();
  //log('view page', path);
  const { pathname } = useRouter();
  useInstagram();

  const get = useGet();
  log('0. viewpage ready');

  useEffect(() => {
    log('*. start loading')
    setPageLoaded(false);
    get.send(env.apiBase + "/api/cms/GetPublishedPage?path=" + pathname);
    // eslint-disable-next-line
  }, [pathname]);

  if (get.loading()) {
    return <Loading />
  } else if (get.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={get.errors} />
  }

  //log(router.pathname);
  //log(get.loading());
  log('1. viewpage component', pageLoaded, get.response);

  const pageData = get.response.data;
  //console.log("page", pageData);

  const menuData = get.response.menu;
  const cmsOption = {};
  log(pageLoaded, !pageLoaded && !!pageData)
  if (!pageLoaded && pageData) {
    //log(pageLoaded, pageData, menuData)
    log('2. dispatching page & menu')
    setPageLoaded(true);
    //dispatch({ type: 'SET_STATE', payload: { page: pageData, menu: menuData } })
    dispatch({ type: 'SET_PAGE', payload: pageData })
    dispatch({ type: 'SET_MENU', payload: menuData })
    document.title = pageData.pageTitle;
    gtm.pageView(pathname, pageData.pageTitle);
    return null;
  } else if (!pageData) {
    return (<NotFound />)
  }
  
  //console.log("viewPage", cmsUtils.payload(pageData, 'Theme'));
  props.onChangeThemeColor('theme--' + (cmsUtils.payload(pageData, 'ColorTheme') ? cmsUtils.payload(pageData, 'ColorTheme') : 'default'));  
  log('3. call rendering memo');
  return <PageMemo pageData={pageData} cmsOption={cmsOption} />;
}

function Page(props) {
  const pageData = props.pageData;
  const cmsOption = props.cmsOption;
  // document.title = pageData.pageTitle;
  log('4. page rendering', pageData, cmsOption);
  const sectionItems = pageData.sections.map((section, index) => {
    return (
      <Section item={section} key={section.sectionName + index} cmsOption={cmsOption}></Section>
    )
  }
  );
  return (
    <>
      <MetaTag data={getMetaTag(pageData)} />
      {/* theme--${cmsUtils.payload(pageData, 'Theme')} */}
    <div className={`cmsPage cmsPage--${pageData.pageTypeCode}`}>
      {sectionItems}
    </div>
    </>
  );
}

function Section(props) {
  log('5. section rendering')
  const item = props.item;
  //const sectionName = item.sectionName;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};
  if (!subItems || subItems.length === 0)
    return (<></>);

  const subItemsElts = subItems.map((subItem, index) =>
    <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
  );
  return (
    <>
      {subItemsElts}
    </>
  );
}

function getMetaTag(pageData) {
  //const country = utils.site.isNZ? 'New Zealand' : 'Australia'
  //const siteName = env.siteName + ' ' + country;
  const country = "";
  const siteName = env.siteName;
  const titleSuffix = env.titleSuffix;

  return {
    title: pageData.pageTitle + ' | ' + titleSuffix,
    description: cmsUtils.payload(pageData, 'Description'),
    keywords: cmsUtils.payload(pageData, 'Keyword'),
    heroImageUrl: utils.site.resourcePath(cmsUtils.payload(pageData, 'ImageUrl')),
    language: 'English',
    country: country,
    siteName: siteName,
    url: utils.site.resourcePath(pageData.pageUrl),
    //canonical: utils.site.resourcePath(pageData.pageUrl)
  }
}