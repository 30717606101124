import React from 'react';
import cmsUtils from 'cms/utils/cmsUtils';
import utils from 'utils';
import { SiteLink } from 'components';
import parse from "date-fns/parse";
import parseISO from "date-fns/parseISO";
//import format from "date-fns/format";

export function BlogPosts(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const posts = getPages(cmsUtils.payload(item, 'BlogPosts'));
  const numOfTileLines = parseInt(cmsUtils.payload(item, 'NumOfTileLines', '2'));
  //console.log(numOfPost, parseInt(numOfPost));
  //console.log(posts);
  const bp = utils.ui.getCurrentBreakpoint();
  const tilesPerLine = {xs:1, sm:1, md:2, lg:2, xl:3, xxl:4}
  const numOfPost = numOfTileLines * (tilesPerLine[bp] || 3);
  const step = numOfPost;
  console.log(bp, numOfTileLines, tilesPerLine, numOfPost)

  const [tilesToShow, setTilesToShow] = React.useState(numOfPost);

  const displayingPosts = posts.length > tilesToShow ? posts.slice(0, tilesToShow) : posts;
  const showMore = posts.length > displayingPosts.length;

  const pageTileElts = displayingPosts.map((page, index) =>
    <Tile page={page} key={index} disableLink={cmsOption.isCmsEdit}></Tile>
  );

  const cssClass = utils.classNames('cms_item', 'blogTiles tiles', item.cssClass || item.anchorName || '');

  return (
    <>
      <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
        {pageTileElts}
      </div>
      {showMore && (<div className="blogTiles__loadMore">
        <span className="thinBtn" onClick={(e) => { setTilesToShow(tilesToShow + step) }}>LOAD MORE</span></div>)}
    </>
  );
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}

function parseDate(dateStr, format) {
  const date = parse(dateStr, format, new Date());
  return date && isValidDate(date) ? date : null;
}

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

function toDate(dateObj) {
  if (!dateObj) return null;
  if (typeof dateObj === "string") {
    return (
      parseDate(dateObj, "yyyy-MM-dd") ||
      parseDate(dateObj, "yyyy-M-d") ||
      parseDate(dateObj, "dd/MM/yyyy") ||
      parseDate(dateObj, "d/M/yyyy") ||
      parseISO(dateObj)
    );
  } else {
    return dateObj;
  }
}

function formatDDMMYYYY(dateObj) {
  const date = toDate(dateObj);
  //console.log(dateObj, date)
  if (!date) return "";
  var dd = date.getDate();
  var mm = date.getMonth() + 1; //January is 0!

  var yyyy = date.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return `${dd}-${mm}-${yyyy}`;
}

function Tile(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const disableLink = props.disableLink;
  const postDate = formatDDMMYYYY(page.postDate);

  return (
    <div className="blogTile">
      <SiteLink className="blogTile__link" to={disableLink ? '' : page.pageUrl}>
        <div className="blogTile__bg" style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}></div>
        <div className="blogTile__content">
          <div className="blogTile__contentInner">
            <div className="blogTile__title">
              {page.pageTitle}
            </div>
            {/* <div className="blogTile__desc">
              {page.tileDesc || page.description}
            </div> */}
            <div>
              {postDate}
            </div>
            <div className="blogTile__readMore thinBtn">READ MORE</div>
          </div>
        </div>
      </SiteLink>
    </div>
  )
}