const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"proxima-nova",serif',
  primaryColor: '#30b0c5',
  fontColor: '#373637',
  bgColor: '#ebebeb',
  themeDefaultColor : '#b84a4b',
  themeRedColor : '#b84a4b',
  themeOrangeColor : '#f79552',
  themeGreenColor : '#a1c288',
  themeBlueColor : '#4cb2bd',
  themeYellowColor : '#dbb079',
  themePurpleColor : '#7d3f60',
  themeGreyColor : '#373637',
  ...overloading
}

export default variables;