import React from "react";
import env from "env";
import { usePost } from "components";
import { useForm } from "form";
import { Button, ErrorMessage } from "components";
import { getContactModel } from "./contactFormModel";
//import ReCAPTCHA from "react-google-recaptcha";

export function Contact(props) {
  const emailReceiver = props.emailReceiver;
  const model = getContactModel({});
  const form = useForm(model, { usePlaceholder: true });
  const post = usePost();
  //const recaptchaRef = React.useRef(null);
  const [mailChimpError, setMailChimpError] = React.useState();
  const [sendingStatus, setSendingStatus] = React.useState(null);

  function onSubmit(e) {
    setMailChimpError(null);
    form.validateForm(e, () => {
      
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute('6LcK8wYqAAAAAKzzt4NX3w4ttlyRlec_VeNPQJ-o', { action: 'submit' }).then((token) => {
          const recaptchaValue = token;
          const value = { ...form.getValue(), emailReceiver: emailReceiver, recaptchaValue : recaptchaValue };
          post.send(env.apiBase + "/api/contact/send", value);
          setSendingStatus("pending");
        });
      });

    });
  }

  if (post.done() && sendingStatus === "pending") {
    form.reset(model);
    //recaptchaRef.current.reset();
    setSendingStatus(null);

    if(post.response.results && (post.response.results.MailChimpError && post.response.results.MailChimpError !== "")){
      setMailChimpError("Mail Chimp Error : " + post.response.results.MailChimpError);
    }
  }

  // if (status === 'done') {
  //   //navigate(`/task/view/${taskId}`)
  //   window.location.reload();
  // }

  const render = (name) => form.renderControl(name, null);

  return (
    <form className="contactForm">
      <div className="form-row">
        <div className="col-md-4 mb-2">{render("name")}</div>
        <div className="col-md-4 mb-2">{render("phone")}</div>
        <div className="col-md-4 mb-2">{render("email")}</div>
      </div>

      <div className="form-row">
        <div className="col-md-12 mb-2">{render("enquiry")}</div>
      </div>

      {/* <div className="form-row">
        <div className="col-md-12 mb-2 recapthca-container">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LcMpgAqAAAAADTxsH_5GY6cAFvTPz9D6JSrst3_"
          ></ReCAPTCHA>
        </div>
      </div> */}

      <div className="form-row">
        <div className="col-md-12 mb-2">
          <div className="actions">
            <Button onClick={onSubmit} status={post.status}>
              Send Message
            </Button>
            <ErrorMessage errors={post.errors} />
            <ErrorMessage errors={mailChimpError} />
            {post.status === "done" && <span style={{display: 'block', marginTop: '1rem', color:'white', fontWeight: 'bold'}}>Successfully sent!</span>}
          </div>
        </div>
      </div>
    </form>
  );
}
