import React from 'react';
import cmsUtils from 'cms/utils/cmsUtils';
import utils from 'utils';

export function BlogPostTitle(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};

  //console.log('item', item);

  const title = cmsUtils.payload(item, 'PageTitle');
  const postDate = cmsUtils.payload(item, 'PostDate');

  const cssClass = utils.classNames('blogView__titleContainer', 'cms_item', item.cssClass || item.anchorName || '');

  return (
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      <h1 className="blogView__title">
        <strong>
          {title}
        </strong>
      </h1>
      <div className="blogView__date">
        {postDate}
      </div>
    </div>
  );
}
