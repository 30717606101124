import React, { useLayoutEffect, useState, useRef } from 'react';
import utils from 'utils';
//import * as yt from './iframeApi';
import * as yt from '../../lib/youtube/iframeApi';

export function YoutubeBanner(props) {
    console.log("props", props);
    const [playerStatus, SetPlayerStatus] = useState('loading');
    const [playerSize] = useState(getSize());
    const { heightSet, videoLinkOrId, playerId } = props.video;
    const videoId = yt.getYoutubeCode(videoLinkOrId);
    const posterImageUrl = yt.thumbnailMax(videoId);
    const ele = useRef(null);
    const [marginTop, setMarginTop] = useState("");
    let countPlayed = 0;
  
    const wResize = () => {
      let height = ele.current.clientHeight;
      let _marginTop = "-"  + (((height / 2) /2) -80) + "px";
      setMarginTop(_marginTop);
    };
  
    const playerVars = {
      'enablejsapi': 1,
      //'autoplay': 1,
      'controls': 0,
      'loop': 1,
      'showinfo': 0,
      'rel': 0,
      'modestbranding': 1,
      'origin': window.location.origin,
      'allowfullscreen': true,
      'wmode': 'transparent'
    }
    //const canPlayVideo = () => window.innerWidth >= 1200
    const canPlayVideo = () => window.innerWidth >= 800
    useLayoutEffect(() => {
      let player = null;
      if (canPlayVideo()) {
        yt.loadApi(() => {
          player = new window.YT.Player(playerId, {
            events: {
              'onStateChange': (e) => {
                switch (e.data) {
                  // case window.YT.PlayerState.PLAYING:
                  //   break;
                  // case window.YT.PlayerState.PAUSED:
                  //   break;
                  case window.YT.PlayerState.ENDED:
                    //console.log('ended', countPlayed);
                    if (++countPlayed < 3) {
                      player.playVideo();
                    } else {
                      SetPlayerStatus('finished');
                    }
                    break;
                  default:
                }
              },
              'onReady': (e) => {
                //console.log('onReady');
                player.mute();
                player.playVideo();
                setTimeout(() => SetPlayerStatus('loaded'), 1000)
              },
              'onError': (e) => { console.log(e) }
            }
          });
        })
        
      }
  
      if(heightSet === "standard"){
        wResize();
        window.addEventListener('resize', wResize);
      }
      return () => {
        if(heightSet === "standard"){
          window.removeEventListener('resize', wResize);
        }
      }
      
    // eslint-disable-next-line
    }, []);
  
    return (
      <div ref = {ele} className={utils.classNames('youtubePlayer', `youtubePlayer--${playerStatus}`, `youtubePlayer--${heightSet}`)} style={{marginTop : marginTop}}>
        <div className="youtubePlayer__mask"></div>
        <div className="youtubePlayer__poster" style={{ backgroundImage: utils.css.bgUrlStyle(posterImageUrl) }}></div>
        {canPlayVideo && <iframe className="youtubePlayer__iframe" id={playerId} width="640" height="390" title="Youtube video"
          style={{ width: `${playerSize.width}px`, height: `${playerSize.height}px`, top: `${playerSize.top}px`, left: `${playerSize.left}px` }}
          src={`https://www.youtube.com/embed/${videoId}?${utils.url.toQueryString(playerVars)}`}
          frameBorder="0"></iframe>}
      </div>
    );
  }
  
  function getSize() {
    const overSize = 1;
    const rate = 9 / 16;
    const w = window.innerWidth * overSize;
    const h = window.innerHeight * overSize;
    const size = { width: w, height: w * rate, left: 0, top: 0 };
    if (size.height < h) {
      // stretch horizontally
      size.height = h;
      size.width = h / rate;
    }
  
    size.left = -(size.width - window.innerWidth) / 2;
    size.top = -(size.height - window.innerHeight) / 2;
    return size;
  }